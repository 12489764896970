import { render, staticRenderFns } from "./WhereIsMyClients.vue?vue&type=template&id=c1dc4050&scoped=true"
import script from "./WhereIsMyClients.vue?vue&type=script&lang=ts"
export * from "./WhereIsMyClients.vue?vue&type=script&lang=ts"
import style0 from "./WhereIsMyClients.vue?vue&type=style&index=0&id=c1dc4050&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1dc4050",
  null
  
)

export default component.exports