
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import PhoneFilter from '@root/src/components/chisai/GCB2/taskList/PhoneFilter.vue'
import moment from 'moment'
import _ from 'lodash'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import { getNewClientsReportData, getNewClientsReportFilters } from '@root/src/api/chisai/GCB2'
import { Project } from '@root/src/types/main'
import Vue from 'vue'
import Breadcrumbs from '@root/src/components/Breadcrumbs.vue'
import SpinnerLoader from '@root/src/components/SpinnerLoader.vue'
const initialFilters = {
    dates: [],
    createdIn: 30,
    periodAfterContact: 90,
    phone: [],
    whoCalled: [],
}
const mapStatusTooltip = {
    ["Новый лид"] : "Не было посещений и записей вообще, на момент звонка (звонок и запись день в день и посещение это тоже лид)",
    ["Не дошедший лид"] : "Были записи, но они отменились. На момент звонка",
    ["Активный клиент"] : "Были посещения последние 95 дней или есть запись на будущее на момент звонка. И запись эта не в день звонка.",
    ["Остывший клиент"] : "Ранее были посещения, но последние 95 дней (до дня звонка) не было, именно посещений."
}
const mapCallType = {
    "missed" : "Пропущенный"
}
export default Vue.extend ({
    name: 'GCB2WhereIsMyClients',
    components: { BaseInputOutlined, PhoneFilter, CustomActivatorSelect, Breadcrumbs, SpinnerLoader },
    data: () => ({
        dateMenu: false,
        mapCallType,
        mapStatusTooltip,
        filters: _.cloneDeep(initialFilters),
        phoneList: [],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        isLoadingTables: true,
        tableTooltip: {
            activator: undefined,
            text: '',
        },
        headers: [
            {
                text: 'Канал обращения/Кто звонил',
                value: 'user_contact',
                desc: 'Бизнес-номер, который используется для приема входящих звонков. Указывается в формате, который указан в подключенной телефонии',
            },
            {
                text: 'Кол-во коммуникаций',
                value: 'count_in_calls',
                desc: 'Кол-во уникальных номеров телефонов, которые звонили в выбранный период',
            },
            {
                text: 'Пропущенные коммуникации',
                value: 'count_missed_calls',
                desc: 'Кол-во звонков, которые в телефонии отмечены как пропущенные',
            },
            {
                text: 'Кол-во записей после коммуникации / Ожидаемая выручка',
                value: 'visit_and_record_count',
                desc: 'Кол-во записей, которые были созданы за выбранный интервал в фильтре + выбранный период, который мог пройти после звонка до записи',
            },
            {
                text: 'Кол-во посещений после контакта / Фактическая выручка',
                value: 'visit_client_count',
                desc: 'Кол-во звонивших, которые фактически посетили салон после записи',
            },
            {
                text: 'Разница между записями и посещениями',
                value: 'diff_record_visit',
                desc: 'Разница в выручке планируемой и фактической',
            },
            {
                text: 'Конверсия из записи в посещения',
                value: 'cr_records_in_visits',
                desc: 'Расчет конверсии созданных записей новыми клиентами в посещения',
            },
            {
                text: 'Кол-во отмененных записей / Сумма средств по отменам',
                value: 'canceled_count',
                desc: 'Кол-во записей, которые были созданы в выбранный период, но новый клиент так и не пришел на эту запись',
            },
            {
                text: 'Кол-во нереализованных записей',
                value: 'num_of_non_realised_record',
                desc: 'Кол-во уникальных звонков от клиентов минус кол-во успешных визитов',
            },
            {
                text: 'Упущенная выручка от нереализованных записей и посещений',
                value: 'lost_revenue',
                desc: 'Кол-во нереализованных записей умноженные на средний чек за визит за выбранный период',
            },
        ],
        detailadHeaders: [
            {
                text : 'Контакт',
                value : 'client_name'
            },{
                text : "Канал обращения/Кто звонил",
                value : "status"
            },{
                text : "Тип звонка",
                value : "calls_type"
            }, {
                text : "Кол-во записей/посещений/отмен после коммуникации",
                value : "all_visit_cnt"
            },
            {
                text : "Выручка по записям/посещениям/отменам",
                value : "fact_revenue"
            }
        ],
        // Сами данные будем подставлять сюда
        items: [
            {
                status: 'Остывший клиент',
                factRevenue: 104373,
                lostRevenue: 71678,
                planRevenue: 104373,
                canceledCount: 6,
                countInCalls: 54,
                businessNumber: '79267792186',
                diffRecordVisit: 0,
                countMissedCalls: 4,
                visitClientCount: 45,
                crRecordsInVisits: 91.2,
                visitAndRecordCount: 68,
                crClientRecordToVisit: 97.8,
                numOfNonRealisedRecord: 9,
                crCallToVisitAndRecord: 125.926,
            },
        ],
    // Также для второй таблицы будем брать данные отсюда
        detailedTable: [
            {
                status: 'new_lead',
                clientId: 'Нет данных о клиенте',
                clientName: 'Алиса',
                callsType: 'missed',
                clientPhone: '+73472151148',
                factRevenue: 0,
                lostRevenue: 0,
                planRevenue: 0,
                allVisitCnt: 0,
                factVisitCount: 0,
                canceledVisitCount: 0,
            },
        ],
    }),
    watch: {
        dateMenu(val) {
            if (!val && this.filters.dates.length === 1) {
                this.filters.dates.push(this.filters.dates[0])
            }
            if (!val) {
                this.getFilters()
            }

        },
    },
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Где мои новые клиенты?',
                    to: '',
                    disabled: true,
                },
            ]
        },
        whoCalledUnique(){
            const statusList = this.items.map(el => el.status)
            const result = statusList.reduce((acc, item) => {
                if (acc.includes(item)) {
                    return acc;
                }
                return [...acc, item];
            }, []);
            return result.map(el => {
                return {
                    status: el
                }
            })
        },
        dateRangeText() {
            const formatedDates: any[] = this.sortedDates.map((date) =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates() {
            return [
                ...this.filters.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },
        filteredItems() {
            if (this.filters.whoCalled.length === 0) {
                return this.items
            } else {
                return this.items.filter(el => this.filters.whoCalled.includes(el.status))
            }
        },
        filteredDetailedtable() {
            if (this.filters.whoCalled.length === 0) {
                return this.detailedTable
            } else {
                return this.detailedTable.filter(el => this.filters.whoCalled.includes(el.status))
            }
        }
    },
    methods: {
        setDefaultDates() {
            this.filters.dates = [
                moment()
                    .subtract(30, 'days')
                    .format(this.datePickerDateFormat),
                moment()
                    .subtract(0, 'days')
                    .format(this.datePickerDateFormat),
            ]
        },
        toPercent(item) {
            return `${item.toFixed(1)}%`
        },
        resetFilters() {
            this.filters = _.cloneDeep(initialFilters)
            this.setDefaultDates()
            this.getDataFromWhereIsMyClient(true)
        },
        // TODO: вынести эту функцию и переиспользовать
        async handleTableMouseOver(e) {
            const target = e.target
            const header = [target, target?.parentElement].find((el) => el?.tagName === 'TH')
            if (header) {
                const elements = Array.from(header.parentElement.children)
                const elId = elements.findIndex((el) => el === header)
                const desc = this.headers[elId].desc
                this.tableTooltip.text = desc
                this.tableTooltip.activator = elements[elId]
            }
        },
        // Примерный вариант того, как мы будем получать данные
        async getDataFromWhereIsMyClient(isReset?: boolean){
            this.isLoadingTables = true
            if (this.dateMenu) {
                this.dateMenu = false
            }
            let data
            if (isReset) {
                this.setDefaultDates()
                data = await getNewClientsReportData({
                projectId: this.project.id,
                filters: {
                        userContact: initialFilters.phone,
                        dateFrom: this.filters.dates[0],
                        dateTo: this.filters.dates[1],
                        delayFromContact: initialFilters.createdIn,
                        daysReturnedToLead: initialFilters.periodAfterContact
                },
                outputList: {
                        statByCall: true,
                        detailedTable: true
                }             
                
            })
            } else {
                data = await getNewClientsReportData({
                projectId: this.project.id,
                filters: {
                        userContact: this.filters.phone,
                        dateFrom: this.filters.dates[0],
                        dateTo: this.filters.dates[1],
                        delayFromContact: this.filters.createdIn,
                        daysReturnedToLead: this.filters.periodAfterContact
                },
                outputList: {
                        statByCall: true,
                        detailedTable: true
                }             
                
            })
            }
            this.items = data.data['stat_by_call'] || []
            this.detailedTable = data.data['detailed_table'] || []
            if(this.filters.whoCalled.length > 0) {
                const statusArr = this.whoCalledUnique.map(el2 => el2.status)
                for (let el of this.filters.whoCalled) {
                    if(!statusArr.includes(el)) {
                        this.filters.whoCalled = this.filters.whoCalled.filter(el3 => el3 !== el)
                    }
                }
            }
            if (data.error) {
                this.$store.dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            this.isLoadingTables = false
        },
        async getFilters() {
            const data = await getNewClientsReportFilters({
                projectId: this.project.id,
                filtersDate: {
                    dateFrom: this.filters.dates[0],
                    dateTo: this.filters.dates[1],
                }
            })
            let dataToArr = []
            if (data.data?.filters['user_contact_list']) {
                dataToArr = Object?.values(data.data.filters['user_contact_list']) || []
            }
            this.phoneList = dataToArr.map((el: any) => {
                return {name: el.option, id: el.option}
            })
        },
        async init() {
            this.setDefaultDates()
            await this.getFilters()
            await this.getDataFromWhereIsMyClient(true)
        }
    },
    mounted() {
        this.init()
    },
})
